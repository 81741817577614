import React, { useContext } from 'react'
import styled from 'styled-components'

import { FlexGrid, TermsAndConditions as UDSTermsAndConditions, Footnote } from '@telus-uds/components-web'

import { SiteBuilderContext } from '../renderer/context/SiteBuilderContext'

const LegalContainer = styled.div({
  overflow: 'hidden',
})

// kds.css overrides the ol list style to none, so we need to override it back to decimal
const StyledKoodoTermsAndConditions = styled.div({
  ol: {
    listStyle: 'decimal',
  },
})

export default function Legal() {
  const { brand, activeFootnote, dictionary, showFootnote, setShowFootnote, locale } = useContext(SiteBuilderContext)

  let items = []
  if (dictionary?.legal) {
    items = Object.keys(dictionary.legal).map((key) => dictionary.legal[key].value)
  }

  let nonIndexItems = []
  if (dictionary?.nonIndexedLegal) {
    nonIndexItems = Object.keys(dictionary.nonIndexedLegal).map((key) => dictionary.nonIndexedLegal[key].value)
  }

  if (items.length <= 0 && nonIndexItems.length <= 0) {
    return null
  }

  const TermsAndConditions = (
    <UDSTermsAndConditions
      data-testid="terms-and-conditions"
      copy={locale?.language || 'en'}
      indexedContent={items}
      nonIndexedContent={nonIndexItems}
    />
  )

  return (
    <LegalContainer>
      <FlexGrid>
        <FlexGrid.Row>
          <FlexGrid.Col>
            <Footnote
              data-testid="uds-footnote"
              copy={locale?.language || 'en'}
              number={activeFootnote.number}
              content={activeFootnote.content}
              onClose={(e, options) => {
                setShowFootnote(false)
                if (options.returnFocus) {
                  activeFootnote?.returnRef?.current?.focus()
                }
              }}
              isOpen={showFootnote}
            />
            {brand === 'koodo' ? (
              <StyledKoodoTermsAndConditions>{TermsAndConditions}</StyledKoodoTermsAndConditions>
            ) : (
              TermsAndConditions
            )}
          </FlexGrid.Col>
        </FlexGrid.Row>
      </FlexGrid>
    </LegalContainer>
  )
}
